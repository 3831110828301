$font:'Montserrat', sans-serif;
$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "text" : "text",
        "gray" : "gray",
        "black" : "black",
        "white" : "white",
        'text-gray': 'text-gray',
        'text-blue': 'text-blue',
);

$colors : (
        'primary'  : #1c2f40,
        'secondary'  : #ec3323,
        'text-blue':#0f2a64,
        'text'  : #404040,
        'blue'  : #0052a2,
        'text-gray'  : #666666,
        'gray'  : #aaaaaa,
        'white' : #FFFFFF,
        'black' : #000000,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color!important;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}