.footer {
  background: cl(primary);
  position: relative;
  z-index: 2;
  color:cl(white);
  font-size: rem(15px);
  a{
    color: cl(primary);
  }
  &--social {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.5rem;
    width: 100%;
    margin-top: 1rem;
    order: 1;
    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-bottom: 1rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(30px);
      color:cl(primary);
      border:rem(1px solid cl(primary));
      @include media-breakpoint-down(xs) {
        @include circle(40px);
        margin: 0 1rem;
      }
      &:hover {
        text-decoration: none;
        background: cl(primary);
        color: cl(white);
      }
      i {
        font-size: rem(16px);
        @include media-breakpoint-down(xs) {
          font-size: rem(24px);
        }
      }
    }
  }
  &-logo {
    display: block;
    margin: 0 auto 1rem;
    width:rem(280px);
    @include media-breakpoint-down(xs) {
      margin-bottom: 1rem;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  address{
    margin-bottom: 1rem;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0.5rem;
    }
  }
  &-contacts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: rem(16px);
    line-height: rem(26px);
    margin-bottom: 0;
    text-align: center;
    margin-top: 1rem;

    @include media-breakpoint-down(xs) {
      font-size: rem(16px);
    }
    @media (max-width: 500px){
      justify-content: center;
    }
    dt {
      color:cl(white);
      font-size: rem(16px);
      line-height: rem(26px);
      width:rem(30px);
    }
    dd {
      margin: 0;
      a {
        color:cl(white);
      }
    }
  }
  &-copy {
    font-size: rem(14px);
    font-family:$font;
    @include media-breakpoint-down(xs) {
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }

  &--top-line{
    padding: rem(45px 0 25px 0);
    &-inner{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
      .first-col{
        width:30%;
        flex-shrink: 0;
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
        }
        @include media-breakpoint-down(xs) {
          width: 50%;
          order: 1;
        }
        @media (max-width: 500px){
          width: 100%;
          margin-bottom: 1rem;
          text-align: center;
        }
        .link{
          img{
            max-width: 100%;
            width: rem(270px);
          }
          @include media-breakpoint-down(xs) {
            margin-bottom: 1rem;
            display: block;
          }
        }
      }
      .second-col{
        width:40%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: center;
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      .third-col{
        width:30%;
        display: flex;
        justify-content: flex-end;
        @include media-breakpoint-down(xs) {

          width: 50%;
          order: 1;
        }
        @media (max-width: 500px){
          width: 100%;
          justify-content: center;
        }

      }
    }
  }
  &--bottom-line{
    padding: rem(6px 0);
    background-color: cl(primary);
    color: cl(white);
    @include media-breakpoint-down(md) {
      padding-bottom: 6.5rem;
    }
  }
  .sponsord-link{
    img{
      height: rem(20px);
    }
  }
  .w-110{
    width: rem(110px);
    display: inline-block;
    img{
      width: 100%;
    }
  }
}

.apply-mobile-buttom{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: none;
  padding: 1rem 1rem;
  background-color: cl(white);
  z-index: 40;
  text-align: center;
  box-shadow: rem(5px 0 10px rgba(0,0,0,0.2));
  @include media-breakpoint-down(md) {
    display: block;

  }
  .btn{
    min-width: rem(250px);
    @include media-breakpoint-down(xs) {
      width: 100%;
      min-width: unset;
    }
  }
}