.modal{
  background-color: cl(black,0.7);
  padding: 0!important;
  .modal-dialog {
    max-width: rem(700px);
    @include media-breakpoint-down(xs) {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .modal-header,
  .modal-footer{
    border: 0;
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
    box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.48);
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(10px);
    right: rem(10px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
    &:focus{
      outline: none;
    }
  }
  h2{
    font-size: rem(24px);
    line-height: rem(34px);
    margin: rem(0 0 0px);
    font-family: $font;
    font-weight: 700;
    text-align: center;
    text-transform: none;
    @include media-breakpoint-down(xs) {
      font-size: rem(22px);
      line-height: rem(32px);
    }
  }
  &.info-modal{
    font-size: rem(18px);
    line-height: rem(28px);
    font-family: $font;
    color: cl(text-gray);
    text-align: center;
    img{
      width: 100%;
      object-fit: contain;
      max-height: 50vh;
      margin-bottom: 1rem;
    }
    p{
      margin-bottom: 1rem;
    }
    .modal-header{
      padding: 2rem 2rem 1rem 2rem;
      justify-content: center;
      @include media-breakpoint-down(xs) {
        padding: 2rem 1.5rem 1rem 1.5rem;
      }
    }
    .modal-body{
      padding: 1rem 2rem 2rem 2rem;
      @include media-breakpoint-down(xs) {
        padding: 0.5rem 1rem 1rem 1rem;
      }
    }
    video{
      width: 100%;
      object-fit: contain;
      max-height: 63vh;
    }
    .video-holder{
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    iframe{
      width: 100%;
    }
  }
}

.fadeIn{
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}