.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(text);
	min-height: 100vh!important;
	background: cl(white);
	font-family: $font;
	line-height: 1.5;
	overflow-x: hidden;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@media screen and (min-width: 2049px) {
		font-size: calc(20px + 15 * ((100vw - 2049px) / 4096));
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(md) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
	main{
		flex-grow: 1;
		min-height: calc(100vh - 40.5rem);
		@include media-breakpoint-down(sm) {
			min-height: calc(100vh - 53.5rem);
		}
		@include media-breakpoint-down(xs) {
			min-height: calc(100vh - 63.5rem);
		}
	}
	&.modal-open{
		padding: 0!important;
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 77.5rem;
	max-width: var(--size);
	position: relative;
	z-index: 2;
	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
	&--small {
		--size: 54rem;

	}
}
.wrapper {
	padding: rem(0 190px);
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}

h2 {
	font-size: rem(40px);
	line-height: 1.2;
	text-transform: uppercase;
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: 700;
	color: cl(text-blue);
	@include media-breakpoint-down(xs) {
		font-size: rem(25px);
	}
}

h3 {
	font-size: rem(24px);
	line-height: 1.4;
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(20px);
	}
}
h4 {
	font-size: rem(20px);
	line-height: rem(30px);
	margin: rem(0 0 10px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(18px);
	}
}
h5 {
	font-size: rem(15px);
	line-height: rem(25px);
	margin: rem(0 0 10px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(18px);
	}
}

.slick-slide{
	&:focus{
		outline: none;
	}
}

.goog-te-gadget-icon{
	display: none!important;
}
.goog-te-gadget-simple {
	background-color: transparent!important;
	border-left: 0!important;
	border-top: 0!important;
	border-bottom: 0!important;
	border-right: 0!important;
	font-size: 10pt;
	display: inline-block;
	padding-top: 1px;
	padding-bottom: 2px;
	cursor: pointer;
	zoom: 1;
	*display: inline;
}
.goog-te-menu-value{
	span{
		font-size: rem(15px)!important;
		color: cl(primary)!important;
		font-family: $font!important;
		font-weight: 700!important;
		text-transform: uppercase!important;
	}
	img{
		display: none!important;
		margin-left: 0!important;
		margin-right: 0!important;
	}
}
//#google_translate_element span[style="color: rgb(118, 118, 118);"] {
//	display:none;
//}
//#google_translate_element span[style="color: rgb(155, 155, 155);"] {
//	display:none;
//}

#google_translate_element span[style="border-left: 1px solid rgb(187, 187, 187);"] {
	display:none;
}

body .uwy.userway_p1 .uai {
	top: 90% !important;
}
.userway-s4-1-ff,
.userway-s4-2-ff,
.userway-s4-3-ff,
.userway-s4-4-ff{
	line-height: 1.5;
	p,div,span,a{
		line-height: 1.5;
		hyphens: auto;
	}
	body{
		line-height: 1.5;
	}
	.header.header--inner-pages .header--inner-block-bottom {
		flex-wrap: wrap;
	}
	.header {
		&.header-scroll {
			position: absolute;
		}
	}
	.footer--top-line-inner{
		flex-wrap: wrap;
	}
	.header-nav>ul>li .drop ul li a {
		line-height: 1.5;
	}
}


.breadcrumb {
	background: none;
	padding: rem(26px 0 5px 0);
	color:cl(secondary);
	margin: 0;
	&-item {
		color:cl(secondary);
		font-size: rem(13px);
		&:before {
			color:cl(secondary)!important;
		}
		&.active {
			color:cl(primary);
		}
		a {
			color:cl(secondary);
			&:hover {
				text-decoration: none;
				color:cl(primary);
			}
		}
	}
}