/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat, cl(primary, 0.5);
  color: cl(white);
  &--home {
    max-height: calc(100vh - 5.5rem);
    height: 35.41vw;
    margin-bottom: rem(60px);
    padding-bottom: rem(50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-image: -moz-linear-gradient( to right, rgb(28,47,64) 0%, rgb(29,61,90) 100%);
    background-image: -webkit-linear-gradient( to right, rgb(28,47,64) 0%, rgb(29,61,90) 100%);
    background-image: -ms-linear-gradient( to right, rgb(28,47,64) 0%, rgb(29,61,90) 100%);
    background-image: linear-gradient( to right, rgb(28,47,64) 0%, rgb(29,61,90) 100%);


    @include media-breakpoint-down(md) {
      height: 66vw;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(xs) {
     height: rem(450px);
    }

    .left-deco{
      position: absolute;
      width: 34.25vw;
      left: -12.5vw;
      top: -4vw;
      opacity: 0.14;
      z-index: 6;
      animation: circle 70s infinite ease-in-out;
      @include media-breakpoint-down(md) {
      display: none;
      }
    }
    .container{
      max-width: 87.5vw;
      z-index: 10;
      position: relative;
    }
    .slider-wrap{
      //position: absolute;
      bottom: rem(50px);
      width: 42%;
      position: absolute;
      left: 3%;
      @include media-breakpoint-down(md) {
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 12;
        bottom: rem(75px);
      }
    }
    .banner--slider{
    //  margin: rem(150px -10px 0 -80px);
      padding: rem(0 55px);

      .slick-list{
        padding: 0!important;
      }
      .slick-track{
        display: flex!important;
        align-items: stretch;
      }
      .slick-prev,
      .slick-next{
        position: absolute;
        @extend .pos-centerY;
        border: 1px solid cl(white);
        background-color: transparent;
        font-size: rem(20px);
        width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        cursor: pointer;
        color: cl(white);
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(sm) {
          width: rem(30px);
          height: rem(30px);
        }
        &:focus{
          outline: none;
        }
        &:hover{
          background-color: cl(white);
          color: cl(secondary);
        }
      }
      .slick-prev{
        left: rem(0);

      }
      .slick-next{
        right: rem(0px);
      }
      .item{
        display: flex;
        height: auto;

        .item-inner {
          flex-grow: 1;
          padding: rem(0 35px);
          position: relative;
          font-size: rem(23px);
          font-weight: 700;
          line-height: 1.25;
          text-align: center;
          color: cl(white);
          @include media-breakpoint-down(lg) {
            font-size: rem(16px);
          }
          a{
            display: block;
            color: cl(white);
          }
        }
        &.slick-current {
          .item-inner {
            &:after {
              content: "";
              position: absolute;
              width: 1px;
              right: 0;
              height: 100%;
              background-color: cl(white);
              top: 0;
              @include media-breakpoint-down(xs) {
                display: none;
              }
            }

            &:before {
              content: "";
              position: absolute;
              width: 1px;
              left: 0;
              height: 100%;
              background-color: cl(white);
              top: 0;
              @include media-breakpoint-down(xs) {
                display: none;
              }
            }
          }
        }
      }
    }
    .banner-text {
      position: relative;
      width:100%;
      height: 100%;
      color:cl(white);
      z-index: 6;
      display: flex;
      flex-direction: column;
      justify-content:flex-end;
      align-items: flex-start;
      @include media-breakpoint-down(md) {
        text-align: center;
        align-items: center;
        justify-content: center;
      }
      .page-ttl{
        font-size: rem(120px);
        line-height: 1;
        font-family: $font;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        max-width: rem(1000px);
        font-weight: 700;
        letter-spacing: 0.1em;
        @include media-breakpoint-down(lg) {
          font-size: rem(80px);
        }
        @include media-breakpoint-down(sm) {
          font-size: rem(70px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(80px);
        }
      }
      .page-sub-ttl{
        font-size: rem(20px);
        line-height: 1.5;
        font-family: $font;
        color: rgb(255, 255, 255);
        max-width: rem(1000px);
        font-weight: 400;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
          font-size: rem(18px);
          text-align: center;
        }
        b{
          font-weight: 700;
        }
      }
      p {
        margin: 0;
      }
    }
  }
  &--page {
    height: rem(400px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(30px 0 30px 0);
    background:left /cover no-repeat, cl(secondary);
    color: cl(white);
    margin-top: rem(70px);
    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: cl(black, 0.15);
      top: 0;
      left: 0;
    }
  }
  &-video {
    transform: skewX(16deg);
    right: -16%;
    top: -5px;
    overflow: hidden;
    position: absolute;
    box-shadow: -0.977px -2.837px 57px 0px rgba(0, 0, 0, 0.21);
    z-index: 5;
    width: 69vw;
    height: 38.5vw;
    border-radius: rem(0 0 0px 60px);
    background-color: cl(primary);
    @include media-breakpoint-down(md) {
      transform:none;
      right: 0;
      top: 0;
      overflow: hidden;
      position: absolute;
      box-shadow: -0.977px -2.837px 57px 0px rgba(0, 0, 0, 0.21);
      z-index: 1;
      width: 100vw;
      height: 66vw;
      border-radius:0;
    }
    @include media-breakpoint-down(xs) {
      height: rem(450px);
    }
    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: cl(black, 0.2);
      top: 0;
      left: 0;
      display: none;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    video {
      position: absolute;
      left: -16%;
      transform: skewX(-16deg);
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-down(md) {
        transform:none;
        left: 0;
      }
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page-ttl{
      font-size: rem(70px);
      line-height: 1;
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      max-width: rem(1000px);
      font-weight: 700;
      letter-spacing: 0.1em;
      @include media-breakpoint-down(lg) {
        font-size: rem(80px);
      }
      @include media-breakpoint-down(sm) {
        font-size: rem(70px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(45px);
      }
    }
    .page-sub-ttl {
      font-size: rem(20px);
      line-height: 1.5;
      font-family: $font;
      color: rgb(255, 255, 255);
      max-width: rem(1000px);
      font-weight: 400;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      @include media-breakpoint-down(sm) {
        font-size: rem(18px);
        text-align: center;
      }

      b {
        font-weight: 700;
      }
    }
    p {
      margin: 0;
    }
  }

}
/* banner end */


.countdown-block{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  // background-color: cl(white);
  //position: absolute;
  z-index: 15;
 // top: 33.5vw;
  position: relative;
  margin-top: -6rem;
  margin-left: auto;
  margin-right: 5rem;
  border-radius: rem(0px 10px 0px 10px);
  background-color:cl(primary);
  @include media-breakpoint-down(lg) {
  //  margin-top:-6rem auto 2rem;
    margin-right: 1rem;
    margin-top: -7rem;
  }
  @include media-breakpoint-down(md) {
    width: 90vw;
    justify-content: center;
    margin:-2rem auto 2rem;
  }
  @include media-breakpoint-down(sm) {
    margin:-2rem auto 2rem;
  }
  .name{
    font-size: rem(18px);
    line-height: rem(26px);
    color: cl(white);
    font-weight: 700;
    padding: rem(8px 15px);
    @extend .ttu;
    letter-spacing: 0.3em;
    @include media-breakpoint-down(xs) {
      font-size: rem(15px);
    }
  }
  .time-block{
    min-width: rem(65px);
    @extend .ttu;
    font-size: rem(15px);
    line-height: 1.1;
    color: cl(white);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: rem(8px 20px);
    letter-spacing: 0.3em;
    position: relative;
    @include media-breakpoint-down(xs) {
      font-size: rem(13px);
    }
    &:last-child{
      &:before{
        display: none;
      }
    }
    &:before{
      content: "";
      width: 1px;
      height: 50%;
      right: 0;
      background-color: cl(white);
      top: 25%;
      position: absolute;
    }
    .time{
      font-size: rem(32px);
      margin-bottom: 0.4rem;
      @include media-breakpoint-down(xs) {
        font-size: rem(22px);
      }
    }
  }
}