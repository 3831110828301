.home-mission-section{
  background: center bottom/ cover no-repeat , cl(primary);
  color: cl(white);
  background-attachment: fixed;
  z-index: 2;
  padding: rem(80px 0 100px 0);
  @include media-breakpoint-down(md) {
    padding: rem(40px 0 50px 0);
    background-attachment: scroll;
  }
  h2{
    color: cl(white);
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  .mission-wrap{
    position: relative;
    width: 85.93vw;
    margin: 0 auto;
    line-height: 1.65vw;
    font-size: 0.95vw;

    @include media-breakpoint-down(md) {
      line-height: rem(26px);
      font-size: rem(16px);
      width: calc(100% - 30px);
    }
    .img-block-bottom-mission{
      position: absolute;
      top:72%;
      left: 0;
      width: 35%;
      @include media-breakpoint-down(md) {
        position: relative;
        width: 100%;
        top: 100%;
      }
      h2{
        color: cl(white);
        font-size: 2vw;
        @include media-breakpoint-down(md) {
          font-size: rem(30px)
        }
      }
    }
  }
  .images-wrap{
    width: 85.93vw;
    position: relative;
    height: 47vw;
    margin: 0 auto;
    @include media-breakpoint-down(xs) {
      width:  100%;
      height: unset;
    }
    .img-block{
      position: absolute;
      z-index: 1;
      width: 24%;
      height: 27.77%;
      background-size: cover;
      background-position:center;
      background-repeat: no-repeat;
      box-shadow: 15.391px 42.286px 62px 0px rgba(0, 0, 0, 0.22);
      @include media-breakpoint-down(xs) {
        width: 100%;
        height: unset;
        position: relative!important;
        margin-bottom: 1rem;
        padding-bottom: 62.5%;
        top: unset!important;
        bottom: unset!important;
        left: unset!important;
        right: unset!important;
      }
      &:after{
        content: '';
        background-size: 100% auto;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-image: url("../img/shadow-2.png");
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
      .ttl{
        color: rgb(255, 255, 255);
        position: absolute;
        @extend .pos-centerX;
        bottom: 1rem;
        width: 100%;
        z-index: 4;
        text-align: left;
        font-size: 1.5vw;
        line-height: 1.2;
        font-weight: 700;
        padding: 0 1rem;
        @include media-breakpoint-down(xs) {
          font-size: rem(18px);
        }
      }
      &.img-block-top-left{
        top: 0;
        left: 9.57%;
      }
      &.img-block-top-right{
        top: 0;
        right: 9.57%;
      }
      &.img-block-center-left{
        left: 0;
        top: 33.7%;
      }
      &.img-block-center-right{
        right: 0;
        top: 33.7%;
      }
      &.img-block-bottom-right{
        right: 3.5%;
        top: 71.6%;
      }

    }

    .img-center-logo{
      width: 30%;
      position: absolute;
      @extend .pos-centerX;
      top: 23%;
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
}

.home--other-options-section{
  background: center bottom/ 100% auto no-repeat , cl(white);
  padding: rem(50px 0 110px 0);
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0 70px 0);
  }
  .lines-deco{
    position: absolute;
    width: 100%;
    bottom: 2rem;
    left: 0;
    animation: line 12s infinite ease-in-out;
    @include media-breakpoint-down(sm) {
      top: unset;
      bottom: 0rem;
      width: 400%;
    }
  }
  .container{
    max-width: rem(1460px);
  }
  .slogan-text{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(40px);
    font-weight: 700;
    @extend .ttu;
    letter-spacing: 0.02em;
    color: cl(text-blue);
    margin-bottom: rem(25px);
    flex-wrap: wrap;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
    img{
      height: rem(46px);
      margin: 0 0.625rem;
    }
  }
  .options-block{
    flex-wrap: wrap;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
    .item{
      width: 14.2%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.1vw;
      line-height: 1.5vw;
      text-align: center;
      font-weight: 700;
      flex-shrink: 0;
      margin-bottom: rem(20px);
      @include media-breakpoint-down(lg) {
        width: 33.3%;
      }
      @include media-breakpoint-down(md) {
        font-size: 2.1vw;
        line-height: 2.5vw;
      }
      @include media-breakpoint-down(sm) {
       width: 50%;
        font-size: rem(25px);
        line-height: 1.5;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      &:nth-child(1){
        padding-top: 7vw;
        @include media-breakpoint-down(lg) {
          padding-top: 5rem;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
        .ttl{
          top: 17.5vw;
          @include media-breakpoint-down(lg) {
            position: relative;
            top: unset;
            transform: none;
            left: unset;
          }
        }
      }
      &:nth-child(2){
        @include media-breakpoint-down(lg) {
          padding-top: 0;
        }
        &:before{
          content: "";
          position: absolute;
         top: 2vw;
          // width: 27vw;
           width: 215%;
          height: 6vw;
          @extend .pos-centerX;
          background-image: url('../img/line-1.png');
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          @include media-breakpoint-down(lg) {
           display: none;
          }
        }
      }
      &:nth-child(3){
        padding-top: 7vw;
        @include media-breakpoint-down(lg) {
          padding-top: 5rem;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
        .ttl{
          top: 17.5vw;
          @include media-breakpoint-down(lg) {
            position: relative;
            top: unset;
            transform: none;
            left: unset;
          }
        }
      }
      &:nth-child(4){
        @include media-breakpoint-down(lg) {
          padding-top: 5rem;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
        &:before{
          content: "";
          position: absolute;
          top: 2vw;
           width: 215%;
          height: 6vw;
          @extend .pos-centerX;
          background-image: url('../img/line-1.png');
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }
      &:nth-child(5){
        padding-top: 7vw;
        @include media-breakpoint-down(lg) {
          padding-top: 0rem;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
        .ttl{
          top: 17.5vw;
          @include media-breakpoint-down(lg) {
            position: relative;
            top: unset;
            transform: none;
            left: unset;
          }
        }
      }
      &:nth-child(6){
        @include media-breakpoint-down(lg) {
          padding-top: 5rem;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
        &:before{
          content: "";
          position: absolute;
         top: 2vw;
          // width: 27vw;
           width: 215%;
          height: 6vw;
          @extend .pos-centerX;
          background-image: url('../img/line-1.png');
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }
      &:nth-child(7){
        padding-top: 7vw;
        @include media-breakpoint-down(lg) {
          padding-top: 0rem;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
        .ttl{
          top: 17.5vw;
          @include media-breakpoint-down(lg) {
            position: relative;
            top: unset;
            transform: none;
            left: unset;
          }
        }
      }

      &:hover{
        text-decoration: none;
        color: cl(secondary);
      }
      .icon{
        width: 11vw;
        height: 11vw;
        margin-bottom: 1.25rem;
        position: relative;
        display: block;
        z-index: 3;
        background: center bottom/ contain no-repeat;
        @include media-breakpoint-down(lg) {
          width: 10rem;
          height: 10rem;
        }
      }
      .ttl{
        position: absolute;
        top: 9vw;
        @extend .pos-centerX;
        width: max-content;
        color: cl(text-blue);
        @include media-breakpoint-down(lg) {
          position: relative;
          top: unset;
          transform: none;
          left: unset;
          width: auto;
        }
      }
    }

  }
}
.home--leaders-section{
  padding: rem(50px 0 40px 0);
  h2{
    text-align: center;
    color: cl(text-blue);
  }
  .home-leaders-slider{
    padding: rem(0 60px);
    .slick-prev,
    .slick-next{
      position: absolute;
      @extend .pos-centerY;
      border: 1px solid cl(secondary);
      background-color: cl(secondary);
      font-size: rem(20px);
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      cursor: pointer;
      color: cl(white);
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        width: rem(30px);
        height: rem(30px);
      }
      &:focus{
        outline: none;
      }
      &:hover{
        background-color: cl(white);
        color: cl(secondary);
      }
    }
    .slick-prev{
      left: rem(0);

    }
    .slick-next{
      right: rem(0px);
    }
    .item{
      .item-inner{
        display: block;
        padding: 0 1rem;
        img{
          height: rem(107px);
        }
      }
    }

  }
}
.home--news-events-section{
  padding: rem(30px 0 40px 0);
  background: center bottom/100% 100% , no-repeat;
  position: relative;
  h2{
      text-align: center;
  }
  .container{
    z-index: 7;
  }
  .sec-wrap{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: rem(0 50px);
    @include media-breakpoint-down(md) {
      flex-wrap: wrap
    }

    .news-side{
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 5;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .btn{
        margin-top: rem(4px);
      }
      .news-block{
        flex-grow: 1;
        margin-top: rem(20px);
        justify-content: center;
        align-items: stretch;
        display: flex;
        column-gap: 2.6rem;
        flex-wrap: wrap;
        @include media-breakpoint-down(xs) {
          flex-direction: column;
        }
      }
      .news-item{
        width: calc(33% - 2rem);
        box-shadow: -22.779px 29.156px 59px 0px rgba(0, 0, 0, 0.15);
        border-radius: rem(20px 0px 20px 0px);
        background-color: rgb(255, 255, 255);
        font-size: rem(16px);
        line-height: rem(26px);
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column-reverse;
        margin-bottom: rem(10px);
        min-height: rem(100px);
        @include media-breakpoint-down(md) {
          width: calc(50% - 1.5rem);
          margin-bottom: 2rem;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;

        }
        .news-item-inner{
          padding: rem(15px 30px);
        }
        &:hover{
          .link-wrap{
            .link{
              &:before{
                right: 0;
              }
              }
          }
          .news-item-img {
            img {
              width: 102%;
              height: 102%;
            }
          }
        }
        .link-wrap{
          margin-top: rem(20px);
          .link{
            font-size: rem(15px);
            color: cl(primary);
            line-height:rem(30px);
            text-align: left;
            font-weight: 400;
            position: relative;
            padding-right: rem(38px);
            cursor: pointer;
            &:before{
              content: "";
              position: absolute;
              @extend .pos-centerY;
              right: rem(16px);
              background-image: url("../img/svg/arrow.svg");
              background-position: left center;
              background-size: contain;
              background-repeat: no-repeat;
              width: rem(20px);
              height: rem(10px);
              transition: 0.3s ease-in-out;
            }
          }
        }
        .ttl{
          font-weight: 700;
          font-size: rem(24px);
          line-height: rem(34px);
          margin-bottom: rem(160px);
          color: cl(text-blue);
        }
        p{
         margin-bottom: 0;
        }
        .news-item-img{
          flex-shrink: 0;
          width: 100%;
          padding-bottom: 55%;
          position: relative;
          overflow: hidden;
          border-radius: rem(20px 0px 0 0);
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            min-width: auto;
            object-position: center;
            position: absolute;
            @extend .pos-center;
            transition: 0.3s ease-in-out;
          }
        }

        p{
          margin-bottom: 0;
        }
      }
    }
  }
  .right-deco{
    position: absolute;
    width: 31.25vw;
    right: -12.5vw;
    top: rem(90px);
    opacity: 0.14;
    z-index: 0;
    animation: circle 70s infinite ease-in-out;
    @include media-breakpoint-down(xs) {
      width: 61.25vw;
      right: -32.5vw;
    }
  }
}
.home--mission-section{
  padding: rem(80px 0 0px 0);
  background: center/ cover , no-repeat;
  position: relative;
  font-size: rem(24px);
  line-height: rem(40px);
  color: cl(text-blue);
  p{
    margin-bottom: rem(40px);
  }
}
.home--highlist-section{
  padding: rem(20px 0 100px 0);
  background: center/ cover , no-repeat;
  position: relative;
  @include media-breakpoint-down(xs) {
    padding: rem(20px 0 20px 0);
  }
  .left-deco{
    position: absolute;
    width: 33.25vw;
    left: -12.5vw;
    top: -12vw;
    opacity: 0.14;
    z-index: 6;
    animation: circle 70s infinite ease-in-out;
    @include media-breakpoint-down(xs) {
      width: 61.25vw;
      left: -32.5vw;
    }
  }
  .container{
    z-index: 8;
  }
  .block-wrap{
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }
  }
  .album-side{
    width: 39%;
    position: relative;
    @include media-breakpoint-down(md) {
      width: 50%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    .album-link{
      background: right center/ cover no-repeat , cl(primary);
      box-shadow: -22.779px 29.156px 59px 0px rgba(0, 0, 0, 0.15);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: rem(20px 40px);
      width: 100%;
      font-size: rem(20px);
      font-weight: 400;
      line-height: 1.5;
      color: rgb(255, 255, 255);
      border-radius: rem(20px 20px 20px 0px);
      min-height: 12vw;
      @include media-breakpoint-down(lg) {
        min-height: 19vw;
        line-height: 1;
      }
      @include media-breakpoint-down(xs) {
       max-width: calc(100% - 5rem);
        margin: 0 auto 1rem;
        text-align: center;
      }
      .ttl{
        font-size: rem(40px);
        font-weight: 700;
        @extend .ttu;
        @include media-breakpoint-down(md) {
          font-size: rem(28px);
        }
      }
    }
  }
  .slider-side{
    width: 61%;
    padding-right: rem(70px);
    position: relative;
    padding-left: rem(10px);
    @include media-breakpoint-down(md) {
      width: 50%;
    }
    @include media-breakpoint-down(sm) {
      padding-right: rem(40px);
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
      padding: 0 2.5rem;
    }
    .futured-slider{
      .slick-prev,
      .slick-next{
        position: absolute;
        border: 1px solid cl(white);
        background-color: transparent;
        font-size: rem(20px);
        width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        cursor: pointer;
        color: cl(white);
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 2rem;
        @include media-breakpoint-down(sm) {
          width: rem(30px);
          height: rem(30px);
          border: 1px solid cl(primary);
          color: cl(primary);
          top: 50%;
          transform: translateY(-50%);
          bottom: unset;
        }
        &:focus{
          outline: none;
        }
        &:hover{
          background-color: cl(white);
          color: cl(secondary);
        }
      }
      .slick-prev{
        left: rem(-70px);
        @include media-breakpoint-down(sm) {
          left: rem(-40px);
        }
      }
      .slick-next{
        right: rem(-70px);
        border: 1px solid cl(primary);
        color: cl(primary);
        @include media-breakpoint-down(sm) {
          right: rem(-40px);
        }
      }
      .item{
        padding: rem(10px 10px);

        img{
          box-shadow: rem(4px 0 7px rgba(0,0,0,0.2));
          height: 12vw;
          border-radius: rem(20px 20px 20px 20px);
          width: 100%;
          object-fit: cover;
          @include media-breakpoint-down(lg) {
            height: 19vw;
          }
          @include media-breakpoint-down(xs) {
            height: 34.5vh;
          }
        }
      }
    }
  }
}
.home--video-album-section{
  padding: rem(80px 0 20px 0);
  background: center/ cover , no-repeat;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(20px 0 20px 0);
  }
  .container{
    z-index: 8;
  }
  .left-deco-block{
    box-shadow: -0.977px -2.837px 57px 0px rgba(0, 0, 0, 0.21);
    width: 66vw;
    height: rem(840px);
    height: 46.75vw;
    position: absolute;
    left: -37%;
    bottom: 0;
    border-radius: rem(0 0 60px 0);
    transform: skewX(-16deg);
    background-color: cl(primary);
    z-index: 3;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      left: -27%;
    }
    @include media-breakpoint-down(md) {
      left: 0;
      bottom: 0;
      transform: none;
      top: unset;
      width: 100vw;
      height: 65vw;
      border-radius:0;
      display: none;
    }
    //@include media-breakpoint-down(sm) {
    //  display: none;
    //}
    &-img{
      position: absolute;
      right: -40%;
      top: 0;
      width: 100%;
      height: 100%;
      transform: skewX(16deg);
      background-size: cover;
      background-position: right top;
      background-repeat: no-repeat;
      @include media-breakpoint-down(md) {
        left: 0;
        bottom: 0;
        right: 0;
        transform: none;
        top: unset;
        width: 100vw;
        height: 65vw;
        border-radius:0;
      }
    }
    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #0052a2;
      opacity: 0.1;
      right: 0;
      top: 0;
    }
  }
  .block-wrap{
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .video-side{
      width: 54.68%;
      position: relative;
      display: flex;
      align-items: flex-end;
      flex-direction: column-reverse;
      color: cl(primary);
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 4rem;
        flex-direction: column;
        align-items: center;
      }
      .video-holder{
        box-shadow: -22.779px 29.156px 59px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        width: 44.7vw;
        padding-bottom: 72%;
        display: block;
        z-index: 8;
        float: right;
        border-radius:  rem(20px 0px 20px 0px);
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
          padding-bottom: 49%;
        }
        @include media-breakpoint-down(md) {
          margin: 0 auto;
          width: 100%;
          padding-bottom: 56.6%;
        }
        &:after{
          content: "";
          clear: both;
        }
        .poster-cover{
          width: 100%;
          border-right: rem(20px 0px 20px 0px);
          height: 100%;
          object-fit: cover;
          position: absolute;
          @extend .pos-center;
          z-index: 1;
        }
        video{
          border-radius: rem(20px 0px 20px 0px);
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          @extend .pos-center;
          &[poster]{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        iframe{
          width: 100%!important;
          height: 100%!important;
          z-index: 0;
          position: absolute;
          @extend .pos-center;
        }
        &.active{
          .poster-cover{
            display: none;
          }
          .play{
            display: none;
          }
          &:hover{
            cursor: pointer;
            .pause{
              display: block;
            }
          }
        }
        &:hover{
          cursor: pointer;
          .play{
            img {
              width: rem(78px);
              height: rem(78px);
              @include media-breakpoint-down(md) {
                width: rem(58px);
                height: rem(58px);
              }
            }
          }
        }
        .play{

          position: absolute;
          @extend .pos-center;
          z-index: 4;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transition: 0.5s ease-in-out;
          transform-origin: center;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            width: rem(70px);
            height: rem(70px);
            position: relative;
            z-index: 4;
            opacity: 0.7;
            @include media-breakpoint-down(md) {
              width: rem(50px);
              height: rem(50px);
            }
          }
        }
      }
      .links-block{
        clear: both;
        color: cl(primary);
        text-align: right;
        padding-top: 2.5rem;
        @include media-breakpoint-down(md) {
          text-align: center;
          padding-top: 0;
          padding-bottom: 3.5rem;
        }

        .main-link{
          font-size: rem(40px);
          color: cl(text-blue);
          font-weight: bold;
          text-transform: uppercase;
          line-height: 1.25;
          display: block;
        }
        .second-link{
          color: cl(text-blue);
          font-size: rem(20px);
          font-weight: 400;
          line-height: 1.25;
          display: block;
        }
      }
    }
    .events-side{
      width: 37.25%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      h2{
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
      .events-block{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .box{

        width: 100%;
        flex-grow: 1;
        padding: rem(0px 0px 0px 90px);
        font-size: rem(16px);
        line-height: rem(26px);
        position: relative;
        margin-top: rem(30px);
        .ttl{
          font-weight: 700;
          font-size: rem(22px);
          line-height: rem(30px);
          margin-bottom: rem(5px);
          color: cl(primary);
        }
        .event-date{
          border-radius: rem(0px 10px 0px 10px);
          background-color:cl(primary);
          min-width: rem(65px);
          height: rem(65px);
          @extend .ttu;
          position: absolute;
          left: 0;
          top: 0;
          font-size: rem(16px);
          line-height: rem(26px);
          color: cl(white);
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: rem(8px 15px);
          letter-spacing: 0.3em;
          span{
            font-size: rem(33px);
            letter-spacing: 0;
            line-height: 1;
          }
        }
        img{
          margin-top: rem(20px);
          width: 100%;
          max-height: rem(220px);
          object-fit: contain;
          object-position: center top;
        }
      }
      .btns-wrap{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-top: rem(60px);
        position: relative;
        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
        }
        .btn{
          width: 48%;
          @include media-breakpoint-down(xs) {
            width: 100%;
            margin-bottom: 1.5rem;
          }
        }
      }
    }

  }

}

.separation-section-picture{
  background: center top/ cover no-repeat , cl(white);
  padding: 10.5vw 0 1vw 0;
  position: relative;
  z-index: 1;
  min-height: 32.65vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .marque-wrap{
    //position: absolute;
    // left: -1rem;
    //  bottom: 1rem;
    z-index: 2;
    overflow: hidden;
    .marquee-honor{
      b{
        line-height: 1;
        padding-right: 6vw;
        font-size: 6.2vw;
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        text-transform: uppercase;
        // line-height:1;
        text-align: center;
        text-shadow: 11.491px 9.642px 38px rgba(0, 0, 0, 0.2);
        position: relative;
        img{
          vertical-align: middle;
          width: 3vw;
          padding-bottom: 1vw;
        }
        &:after{
          content: "";
          position: absolute;
          @extend .pos-centerY;
          right: 1.5vw;
          width: 3vw;
          height: 3vw;
          z-index: 2;
          background-image: url("../img/deco.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }
}

.home--leaders-section{
  padding: rem(120px 0 140px 0);
  position:relative;
  @include media-breakpoint-down(md) {
    padding: rem(20px 0 80px 0);
  }
  h2{
    text-align: left;
    margin-bottom: 2rem;
    color: cl(primary);
    @include media-breakpoint-down(md) {
     text-align: center;
    }
  }
  .deco{
    width: 100%;
    position: absolute;
    top:0;
    right: 0;
  }
  .container{
    z-index: 7;
  }
  .right-deco-block{

    position: absolute;
    top: 0;
    transform: skewX(16deg);
    background-color: cl(primary);
    z-index: 3;
    overflow: hidden;
    box-shadow: -.977px -2.837px 57px 0rgba(0,0,0,.21);
    width: 50vw;
    height: 86%;
    right: -4%;
    border-radius: 3rem 0 0 3rem;
    @include media-breakpoint-down(lg) {
      left: -27%;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    &-img{
      position: absolute;
      left: -16%;
      top: 0;
      width: 112%;
      height: 100%;
      transform: skewX(-16deg);
      background-size: cover;
      background-position: left top;
      background-repeat: no-repeat;
      @include media-breakpoint-down(md) {
        left: 0;
        bottom: 0;
        right: 0;
        transform: none;
        top: unset;
        width: 100vw;
        height: 65vw;
        border-radius:0;
      }
    }
    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #0052a2;
      opacity: 0.1;
      right: 0;
      top: 0;
    }
  }
  .home-leaders-slider{
    margin: -2rem -4rem 0 -4rem;
    .item{
      padding: 2rem 1rem;
      .item-inner{
        display: block;
        padding: 1.25rem 0.75rem;
        border-radius:rem(8px);
        background-color: rgb(255, 255, 255);
        box-shadow: rem(-8px 15px 20px 0px rgba(0, 0, 0, 0.1));
        img{
          height: rem(127px);
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

  }
}


@keyframes line{
  0% {transform:   scale(1) skewX(0) skewY(0deg);}
  25% {transform:   scale(1.1) skewX(8deg) skewY(-1deg)}
  75% {transform:  scale(1.2)skewX(-8deg) skewY(1deg)}
  100% {transform:  scale(1) skewX(0) skewY(0deg)}
}
@-webkit-keyframes line{
  0% {transform:   scale(1) skewX(0);}
  25% {transform:   scale(1.1) skewX(8deg)}
  75% {transform:  scale(1.2)skewX(-8deg)}
  100% {transform:  scale(1) skewX(0)}
}


@keyframes circle{
  0% {transform:   rotate(0);}
  100% {transform:  rotate(360deg);}
}
@-webkit-keyframes circle{
  0% {transform:   rotate(0);}
  100% {transform:  rotate(360deg);}
}
