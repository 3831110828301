.president-section{
  padding-top: rem(40px);
  position: relative;
  font-size: rem(18px);
  font-family: $font;
  font-weight: 400;
  line-height: 1.5;

  .president-section-grid{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .text-side{
      width: 57%;
      @include media-breakpoint-down(md){
        width: 100%;
      }
      .signuture{
        height: rem(40px);
      }
    }
    .image-side{
      width: 43%;
      position: relative;
      @include media-breakpoint-down(md){
        width: 100%;
      }
      .director{
        width: rem(414px);
        position: relative;
        display: block;

        @include media-breakpoint-down(md){
        margin-left: auto;
          max-width: 100%;
        }
      }
      .deco{
        position: absolute;
        width: rem(200px);
        left: rem(324px);
        bottom: 0;
        @include media-breakpoint-down(md){
         display: none;
        }
      }
    }
  }
}
.brand-categories-section{
  padding: rem(78px 0 0px 0);
  margin-bottom: -15rem;
  position: relative;
  z-index: 5;
  @include media-breakpoint-down(md){
    margin-bottom: -5rem;
  }
  @include media-breakpoint-down(xs){
    margin-bottom: 0rem;
  }
  .categ-grid{
    .item{
      padding: rem(30px 160px 30px 40px);
      margin-bottom: rem(13px);

      background-color: rgb(255, 255, 255);
      box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.21);
     width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border: 1px solid cl(white);
      @include media-breakpoint-down(md){
        padding: rem(30px 30px 20px 30px);
      }
      @include media-breakpoint-down(sm){
        flex-wrap: wrap;
      }
      &:hover{
        border-color: rgba(25, 48, 66,0.2);
        background-color: cl(white, 0.5);
        cursor:pointer;
      }
      .item-img{
        width: rem(260px);
        height: rem(170px);
        object-fit: contain;
        flex-shrink: 0;
        object-position: left top;
        @include media-breakpoint-down(sm){
         margin: 0 auto 1rem;
        }
      }
      .text-side{
        font-size: rem(18px);
        font-family: $font;
        font-weight: 400;
        line-height: 1.5;
        padding-left: rem(40px);
        @include media-breakpoint-down(sm){
          width:100%;
          padding-left: 0;
        }
        h2{
          font-size: rem(30px);
          font-family: $font;
          margin-bottom: 1.25rem;
          font-weight: 700;
          color: cl(primary);
        }

        p{
          margin-bottom: 1.5rem;
          a{
            color: #3a56bd;
          }
        }
      }
    }
  }
}
.brand-marquee-section{
  .marque-wrap{
    // position: absolute;
    left: -1rem;
    bottom: 1rem;
    z-index: 2;
    overflow: hidden;
    background-color: cl(primary);
    .marquee-brand{
      color: rgba(255, 255, 255, 1);
      font-family: $font;
      font-weight: 700;
      font-size:5vw;
      text-transform: uppercase;
      @include media-breakpoint-down(sm){
        font-size:8vw;
      }
      b {
        color: rgba(255, 255, 255, 1);
        font-size: 5vw;
        font-weight: bold;
        text-transform: uppercase;
        //margin-right: 2rem;
        padding-right: rem(70px);
        position: relative;
        @include media-breakpoint-down(sm){
          font-size:8vw;
        }
        &:after{
          content: "";
          position: absolute;
          @extend .pos-centerY;
          right: rem(20px);
          width: rem(10px);
          height: rem(10px);
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 1);
          z-index: 2;
        }
      }
    }
  }

}

.banner-files-section{
  padding: rem(80px 0 60px 0);
  margin-bottom: -13vw;
  .btn-wrap{
    margin: 0 auto;
    text-align: center;
  }
  .container{
    --size: 79.75rem;
    max-width: var(--size);
    @include media-breakpoint-down(lg) {
      max-width: 95%;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
  .brand-files-block-grid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 1.5rem;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      column-gap: 1.5rem;
    }
    .item {
      width: calc(20% - 1.2rem);
      margin-bottom: 1.5rem;
      cursor: pointer;
      display: block;
      @include media-breakpoint-down(md) {
        width: calc(25% - 2rem);
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-down(sm) {
        width: calc(33% - 2.5rem);
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-down(xs) {
        width: calc(50% - 2rem);

      }
      @media (max-width: 400.98px) {
        width: 100%;
      }
      .file-item {
        box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.21);
        position: relative;
        background: center / contain no-repeat , #e0e0e0;
        padding-bottom: 22%;
        color: cl(white);
        text-align: center;
        font-size: rem(22px);
        line-height: rem(28px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        &:before {
          content: "";
          background: center bottom / cover no-repeat url(../img/shadow-3.png);

          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
        .file-item-img{
          width: 100%;
          padding-bottom: 100%;
          background: center center / contain no-repeat url(../img/no-pdf.jpg);
        }
        .file-item-text {
          position: absolute;
          z-index: 2;
          left: 0;
          width: 100%;
          bottom: 0;
          padding: 0.5rem 0.5rem 1rem;
          hyphens: auto;
          overflow-x: hidden;
          .name {
            font-size: rem(18px);
            hyphens: auto;
            line-height: rem(28px);
          }

        }
      }
    }
  }
}
main{
  min-height: calc(100vh - 26.5rem);
}
.stay-in-touch-section-small{
  position: relative;
  z-index: 1;
  height: 6.4vw;
  background:center/cover no-repeat, cl(primary);
  background-attachment: fixed;
  color: cl(white);
  text-align: center;
  font-size: rem(24px);
  font-family: $font;
  line-height: rem(34px);
  letter-spacing: 0.02em;
  @include media-breakpoint-down(lg) {
    background-attachment: scroll;
  }
  .letter-decor{
    font-family: $font;
    font-size: 6.15vw;
    line-height: 6.15vw;
    letter-spacing: 0.1em;
    color: cl(white);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -0.93vw;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    @include media-breakpoint-down(lg) {
      font-size: 5.7vw;
      bottom: -0.93vw;
      column-gap: 1rem;
    }
    .word{
      display: flex;
    }
  }
}