.staff-page{
  padding-bottom: rem(50px);
  .staff-grid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 5rem;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      column-gap: rem(70px);
    }
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2.5rem;
      width: calc(25% - 4rem);

      @include media-breakpoint-down(md) {
        width: calc(33% - 3rem);
      }
      @include media-breakpoint-down(sm) {
        width: calc(50% - 3rem);
      }
      @media (max-width: 358px){
        width: 100%;
      }
      &:hover{
        text-decoration: none;
        .item-details{
          .name {
            color: cl(secondary);
          }
        }
      }
      &-avatar{
        width: rem(180px);
        height: rem(180px);
        border-radius: 50%;
        background-image: url('../img/no-avatar.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: rem(20px);
        flex-shrink: 0;
      }
      &-details{
        font-size: rem(16px);
        font-weight: 500;
        text-align: center;
        color: cl(text);
        line-height: rem(26px);
        padding: rem(0 10px);
        .name{
          font-size: rem(17px);
          line-height: rem(26px);
          font-weight: 700;
          color: cl(primary);
        }


      }
    }
  }
}
.staff-ttl{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: rem(20px);
  overflow-x: hidden;
  h2{
    flex-shrink: 0;

  }
  .line{
    width: auto;
    background-color: rgb(206, 206, 206);
    height: 1px;
    margin-left: 1rem;
    flex-grow: 1;
    margin-bottom: rem(20px);
  }
}