.btn {

  color:cl(white)!important;
  font-size: rem(16px);
  font-family: $font;
  font-weight: 700;
  line-height: rem(48px);
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid cl(primary);
  background-color: cl(primary);
  cursor: pointer;
  border-radius: rem(25px);
  padding: rem(0 25px);
  letter-spacing: 0.05em;
  outline: 0;
  white-space: nowrap;
  transition: 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  width: max-content;
  min-width: rem(150px);
  z-index: 3;
  @include media-breakpoint-down(sm) {
    white-space: normal;
    width: auto;
    max-width: 100%;
  }
  &:hover,
  &:focus{
    color: cl(white) !important;
    background-color: cl(primary, 0.7);
    border: 1px solid cl(primary);

  }
  &:focus {
    box-shadow:none;
    outline: none;
  }
  &--secondary{
    border: cl(secondary);
    background-color:cl(secondary);
    &:hover,
    &:focus{

      border: cl(secondary);
      background-color:cl(secondary, 0.7);
    }

  }
  &--outline-primary{
    border-color: cl(primary)!important;
    color:cl(primary) !important;
    background-color:cl(white);
    &:hover,
    &:focus{
      color:cl(primary) !important;
      background-color:cl(primary, 0.2);
    }

  }
  &--outline-secondary{
    border-color: cl(secondary)!important;
    background-color:transparent;
        color:cl(secondary)!important;
    &:hover,
    &:focus{
      color:cl(secondary)!important;
      background-color:cl(secondary, 0.7);
    }

  }
  &--small{
    font-weight: 400;
    font-size: rem(16px);
    letter-spacing: 0;
    i{
      font-size: rem(12px);
    }
  }
}




.floating-btn {
  top: 50%;
  right: rem(-65px);
  transform: translateY(-50%) rotate(-90deg);
  position: fixed;
  border-radius: 1.25rem 1.25rem 0 0;
  z-index: 100;
  color: cl(primary);
  font-size: rem(18px);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  box-shadow: 0.942px -11.984px 54px 0px rgba(0, 0, 0, 0.21);
  background-color: cl(white);
  font-weight: 600;
  width: rem(170px);
  height: rem(40px);
  transition: all 0.2s linear;
  @include media-breakpoint-down(lg){
    display: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    padding: 0;
    right: 0.5rem;
    bottom: 1.5rem;
    top: unset;
    transform: none;
    background-color: cl(primary);
    box-shadow: 0.837px 11.971px 54px 0px rgba(0, 0, 0, 0.21);
  }
  &:hover{
    text-decoration: none;
    color: cl(white);
    background-color: cl(primary);
  }
  span{
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  .mobile-icon{
    display: none;
    @include media-breakpoint-down(lg){
      display: block;
      width: 60%;
      object-fit: contain;
    }

  }
}